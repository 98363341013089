import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function SocialButton(props) {
  const { className, backgroundColor, icon, link } = props
  return (
    <Link
      to={link}
      style={{ backgroundColor: backgroundColor }}
      className={`SocialButton ${className}`}
    >
      {icon === "fa-brands fa-x-twitter" && (
          <FontAwesomeIcon icon={['fab', 'x-twitter']} style={{"color": "fff"}} />
      )}
      {icon !== "fa-brands fa-x-twitter" && (
          <i className={icon}></i>
      )}
       Compartir
    </Link>
  )
}

SocialButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  backgroundColor: PropTypes.string,
}

SocialButton.defaultProps = {
  className: "",
  icon: "",
  backgroundColor: "",
}
